body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-success1{
  background-color: #2f8948;
}

.navbar-brand{
  color:white!important;
}

.bs-card:hover{
  opacity: 0.5;
}

.bs-btn-signin{
  background-color: #414341!important;
  border-color: #414341!important;
}



.btn-primary {
  color: #fff!important;
  background-color: #2f8948!important;
  border-color: #2f8948!important;
}
.btn-light{
  color: #fff!important;
  background-color: #2f8948!important;
  border-color: #2f8948!important;
  font-weight: 700;
  padding-top: 15px!important;
  padding-top: 15px!important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

}


.btn-light:hover{
  opacity: .5;
}

.bs-card-body{
  padding-top:20px!important;
  color:#414341;
}

.bs-card{
  border-radius: .5rem!important;
}

.card-header:first-child {
  border-radius: calc(.5rem - 1px) calc(.5rem - 1px) 0 0!important;
  }


.bs-navbar{
  padding-top:10px!important;
    padding-bottom:10px!important;

}

@media (min-width: 992px){
  .bs-navbar{
    padding-top:45px!important;
    padding-bottom:45px!important;
  }

}

@media (min-width: 992px){
  .navbar-brand {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
  }
}

@media (min-width: 768px){
  .bs-card-body {
    height:120px;
  }
}


.bs-navbar-brand{
  color: #2f8948!important;
  font-weight: 600;
  font-size: 2rem!important;
}

#logo{
  margin-top: 10px;
  margin-bottom: 10px;
  width:65%;
  height:65%;
}
@media (min-width: 768px){
  #logo {
    width:75%;
    height:75%;
  }
}

#bs-footer{
  background-image: url('footer.jpg');
  background-repeat: round;
  height:170px;
}

/* @media (min-width: 768px){
  #bs-footer{
    height:190px;
    width:100%;
  }
}

@media (min-width: 992px){
  #bs-footer{
    height:240px;
    width:100%;
  }
} */

bs-form-control-lg{
  min-height: calc(1.5em + 1rem + 12px);
}

.wangyel-studio-font{
  font-size: .9em;
}

.form-check-input {
  width: 1.4rem!important; 
  height: 1.4rem!important;
  cursor: pointer;
 
}

.form-check-input:hover {
  opacity: 0.5;
}

.form-check-label{
  margin-left: 0.5rem;
  cursor: pointer;
}
.form-check-label:hover{
  opacity:0.5;
}
